<template>
  <div class="toolbar_container">
    <div class="toolbar" :class="visible ? 'showToolbar' : 'hideToolbar'">
      <v-btn
        v-for="(item, index) in items"
        :key="index"
        @click.stop="item.action"
        icon
      >
        <v-icon>item.icon</v-icon>
      </v-btn>

      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: "floatingToolbar",
  props: {
    items: {
      type: Array,
    },
    visible: {
      type: Boolean,
    },
  },
}
</script>

<style scoped>
  .toolbar_container {
    /*position: relative;*/
  }

  .toolbar {
    width: auto;
    position: absolute;
    bottom: 20px;
    background: #a5a5a596;
    border-radius: 100px !important;
    margin: 20px;
    backdrop-filter: blur(8px);
    line-height: 0;
    padding: 2px 6px;
    border-bottom: 1px solid #a5a5a5b0;
    border-right:  1px solid #a5a5a5b0;
    border-top:    1px solid #d8d8d8b0;
    border-left:   1px solid #d8d8d8b0;
    transition: opacity 0.2s ease-in-out;
  }

  .hideToolbar{
    opacity: 0;
  }

  .showToolbar {
    opacity: 1;
  }
</style>
