<template>
  <div id="document-viewer">
    <pdf-viewer
      v-if="document.isPDF"
      :src="document.url"
    />

    <quiz-audio-player
      v-if="document.isMP3"
      :document="document"
      :instance="instance"
      :teacher-mode="teacherMode"
    />

  </div>
</template>

<script>
import QuizAudioPlayer from "./quiz_audio_player.vue"
import PdfViewer from "./pdf_viewer.vue"
export default {
  name: "quizzesDocumentsStudentViewer",
  components: {
    PdfViewer,
    QuizAudioPlayer,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
    instance: {
      type: Object,
    },
    teacherMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {

  },
}
</script>

<style scoped>
  #document-viewer {
    height: 100%;
  }
</style>
