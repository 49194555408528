<template>
  <div>
    <audio
      ref="audioPlayer"
      :controls="false"
      controlsList="nodownload"
      preload="auto"
      :src="document.url"
      @play="() => playing = true"
      @ended="shouldStop"
      @pause="() => playing = false"
      @complete="shouldStop"
      @canplaythrough="() => loading = false"
    />


    <v-card light class="ma-2">
      <v-card-text>
        <v-slider
          min="0"
          :max="duration*100"
          :value="currentTime*100"
          readonly
          hide-details
        />

        <v-row class="mb-0">
          <v-col cols="auto" class="ml-2">
            {{ displayCurrentTime }}
          </v-col>

          <v-spacer/>

          <v-col cols="auto" class="mr-2">
            {{ displayDuration }}
          </v-col>
        </v-row>


        <v-row>
          <v-spacer/>

          <v-col cols="auto">
            <v-progress-circular
              indeterminate
              size="24"
              v-if="loading"
            />
            <v-btn
              v-for="(button, index) in buttons"
              :key="index"
              v-if="button.show"
              icon
              @click="button.action"
              :disabled="button.disabled"
            >
              <v-icon large>{{ button.icon }}</v-icon>
            </v-btn>

          </v-col>

          <v-spacer/>

          <v-col cols="auto">

            <v-menu
              v-model="showVolume"
              open-on-hover
              max-width="290"
              min-width="200"
              close-delay="500"
              :close-on-click="false"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                >
                  <v-icon>volume_up</v-icon>
                </v-btn>
              </template>

              <v-card>
                <v-slider
                  v-if="showVolume"
                  :min="0"
                  :max="100"
                  v-model="volume"
                  hide-details
                  @click.stop
                />
              </v-card>
            </v-menu>


          </v-col>
        </v-row>

        <span v-if="!canPause">
          {{ $t('evalmee.documents.audioPlayer.noPause') }}
        </span>
        <span>
          <br/>
          {{ $t('evalmee.documents.audioPlayer.playCount', {playCount: this.document.max_read_count}) }}

          <br/>
          {{ $t(
            'evalmee.documents.audioPlayer.remainingPlay',
            {
              remainingPlayCount: this.remainingPlayCount,
              maxPlayCount: this.document.max_read_count
            }
          ) }}
        </span>
      </v-card-text>

    </v-card>

  </div>
</template>

<script>
export default {
  name: "quizAudioPlayer",
  props: {
    document: {
      type: Object,
      required: true,
    },
    teacherMode: {
      type: Boolean,
      default: false,
    },
    instance: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    playing: false,
    shouldBePlaying: false,
    currentTime: 0,
    duration: 0,
    interval : null,
    loading: true,
    volume: 100,
    showVolume: false,
    playCount: 0,
  }),
  computed: {
    canPause() {
      if(this.teacherMode) return true

      return this.document.can_pause
    },
    canPlay() {
      if(this.loading) return false
      if(this.teacherMode) return true

      return this.playCount < this.document.max_read_count
    },
    displayDuration(){
      return this.secondToDisplayTime(this.duration)
    },
    displayCurrentTime(){
      return this.secondToDisplayTime(this.currentTime)
    },
    buttons () {
      return [
        {
          icon: "play_circle_outline",
          action: this.shouldPlay,
          show: !this.shouldBePlaying,
          disabled: !this.canPlay,
        },
        {
          icon: "pause_circle_outline",
          action: this.shouldStop,
          show: this.shouldBePlaying,
          disabled: !this.canPause,
        },
      ]
    },
    remainingPlayCount() {
      return this.document.max_read_count - this.playCount
    },
  },
  methods: {
    shouldPlay() {
      if(!this.canPlay) return
      this.incrementPlayCount()
      this.shouldBePlaying = true
      this.play()
    },
    shouldStop() {
      this.shouldBePlaying = false
      this.stop()
    },
    play() {
      this.$refs.audioPlayer.play()
    },
    stop() {
      this.$refs.audioPlayer.pause()
    },
    getPlayPosition() {
      this.duration = this.$refs.audioPlayer.duration
      this.currentTime = this.$refs.audioPlayer.currentTime
    },
    secondToDisplayTime(time) {
      return this.$moment(Math.round(time) * 1000).format("m:ss")
    },
    incrementPlayCount() {
      if(this.teacherMode) return
      this.playCount += 1
      this.document.setPlayCount(this.instance, this.playCount)
    },
  },
  watch: {
    playing(playing) {
      if (!playing && this.shouldBePlaying) {
        setTimeout(() => {
          if (this.shouldBePlaying) {
            this.play()
          }
        }, 10)
      }
      if (playing && !this.shouldBePlaying) {
        this.stop()
      }
    },
    volume(volume) {
      this.$refs.audioPlayer.volume = volume / 100
    },
  },
  mounted() {
    this.interval = setInterval( this.getPlayPosition, 50)
    this.$refs.audioPlayer.volume = this.volume / 100
    this.playCount = parseInt(this.document.getPlayCount(this.instance))
  },
  destroyed() {
    clearInterval(this.interval)
  },
}
</script>

<style scoped>

</style>
