<template>
  <v-sheet
    dark
    tile
    class="viewer"
    color="grey darken-3"
  >
    <template v-if="loading && !loadingError">
      <v-progress-linear
        :indeterminate="progress === 0"
        color="primary"
        :value="progress * 100"
      />
      <div class="center">
        <div class="center">
          {{ $t("live.pdfViewer.loading") }}
        </div>
      </div>
    </template>
    <template v-if="loadingError">
      <div class="center">
        <div class="center">
          {{ $t("live.pdfViewer.error") }}
        </div>
        <v-btn
          @click="reloadFile"
          color="primary"
        >
          {{ $t("live.pdfViewer.retry") }}
        </v-btn>
      </div>
    </template>
    <div
      class="pdf_container pl-2 pr-2"
      ref="pdf_embed"
    >
      <vue-pdf-embed
        class="mb-2 mt-2 pdf_page"
        :source="src"
        :key="componentKey"
        :width="pdfWidth"
        @progress="trackProgress"
        @loaded="onLoaded"
        @loading-failed="() => loadingError = true"
        @rendering-failed="() => loadingError = true"
      />


      <floating-toolbar :visible="showToolbar">
        <v-btn
          @click.stop="zoom += 25"
          icon
        >
          <v-icon>zoom_in</v-icon>
        </v-btn>
        <v-btn
          @click.stop="zoom = 100"
          icon
        >
          <v-icon>search_off</v-icon>
        </v-btn>
        <v-btn
          @click.stop="zoom -= 25"
          icon
        >
          <v-icon>zoom_out</v-icon>
        </v-btn>
      </floating-toolbar>
    </div>
  </v-sheet>
</template>

<script>

import FloatingToolbar from "../../shared/floating_toolbar.vue"

const VuePdfEmbed = () => import ("vue-pdf-embed/dist/vue2-pdf-embed.js")

export default {
  name: "PdfViewer",
  components: {
    FloatingToolbar,
    VuePdfEmbed,
  },
  data: () => ({
    progress: 0,
    zoom: 100,
    elementWidth: 400,
    showToolbar: true,
    loading: true,
    componentKey: 0,
    loadingError: false,
  }),
  props: {
    src: {
      type: String,
      required: true,
    },
  },
  methods: {
    trackProgress({ loaded, total }) {
      this.progress = loaded / total
    },
    reset() {
      this.progress = 0
      this.zoom = 100
      this.loading = true
      this.updateComponentKey()
      this.loadingError = false
      setTimeout(() => {
        this.updateComponentKey()
      }, 1)
    },
    reloadFile() {
      this.updateComponentKey()
      this.loadingError = false
    },
    onDelayEnd() {
      setTimeout(() => {
        if (this.loading) {
          this.loadingError = true
        }
      }, 5000)
    },
    onLoaded() {
      this.loading = false
      this.loadingError = false
    },
    updateComponentKey() {
      this.componentKey += 1
    },
  },
  computed: {
    pdfWidth() {
      return this.elementWidth * (this.zoom / 100)
    },
  },
  watch: {
    src: {
      handler: function() {
        this.reset()
        this.onDelayEnd()
      },
      immediate: true,
    },
  },
  mounted() {
    this.$nextTick(()=>{
      const pdf_embed = this.$refs.pdf_embed
      if(pdf_embed){
        this.elementWidth = pdf_embed.clientWidth - 16
      }
    }
    )
  },
}
</script>

<style scoped>
  .pdf_container{
    height: 100%;
    overflow: scroll;
  }

  .viewer {
    height: 100%;
  }

  .pdf_page {
    margin-left: auto;
    margin-right: auto;
  }

</style>
